var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "저장", icon: "save" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "검사항목명",
                            name: "col1",
                          },
                          model: {
                            value: _vm.data.col1,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col1", $$v)
                            },
                            expression: "data.col1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "법적허용 기준값",
                            name: "col2",
                          },
                          model: {
                            value: _vm.data.col2,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col2", $$v)
                            },
                            expression: "data.col2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "자체관리 기준값",
                            name: "col3",
                          },
                          model: {
                            value: _vm.data.col3,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col3", $$v)
                            },
                            expression: "data.col3",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-plant", {
                          attrs: { required: true, type: "edit", name: "col0" },
                          model: {
                            value: _vm.data.col0,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col0", $$v)
                            },
                            expression: "data.col0",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.useFlagItems,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }